<template>
  <apart class="notification is-warning has-padding-100">
    <p>
      This task is scheduled to start
      <strong>{{
        $moment(task.dateScheduled.toDate()).format("dddd Do MMM, YYYY @ HH:mm")
      }}</strong
      >.
    </p>
    <button
      class="button is-dark is-rounded is-outlined is-pulled-right"
      @click="updateSchedule"
    >
      Change schedule
    </button>
  </apart>
</template>

<script>
export default {
  name: "TaskSchedule",
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateSchedule() {
      this.$modal.open({
        component: () => import("@shared/tasks/_scheduleControlModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          taskId: this.task._id,
          dateScheduled: this.task.dateScheduled.toDate()
        }
      });
    }
  }
};
</script>
